<script>
    import { inertia } from '@inertiajs/svelte';

    export let currentRoute;
</script>

<div>
    <nav class="-mb-px flex">
        <a
            href="{'/rounds'}"
            use:inertia
            class=" flex w-1/3 items-end justify-center border-b-2 border-l-0 border-r-0 border-t-0 border-solid px-2 py-4 text-center text-base font-semibold hover:border-gray-300 hover:text-gray-700 sm:whitespace-nowrap {currentRoute ==
            '/rounds'
                ? 'border-indigo-500 text-indigo-600'
                : 'border-gray-200 text-gray-500'}">{'Overview'}</a
        >

        <a
            href="{'/rounds/my'}"
            use:inertia
            class="  flex w-1/3 items-end justify-center border-b-2 border-l-0 border-r-0 border-t-0 border-solid px-2 py-4 text-center text-base font-semibold hover:border-gray-300 hover:text-gray-700 sm:whitespace-nowrap {currentRoute ==
            '/rounds/my'
                ? 'border-indigo-500 text-indigo-600'
                : 'border-gray-200 text-gray-500'}">{'Rounds Participating'}</a
        >

        <a
            href="{'/rounds/history'}"
            use:inertia
            class=" flex w-1/3 items-end justify-center border-b-2 border-l-0 border-r-0 border-t-0 border-solid px-2 py-4 text-center text-base font-semibold sm:whitespace-nowrap {currentRoute ==
            '/rounds/history'
                ? 'border-indigo-500 text-indigo-600'
                : 'border-gray-200 text-gray-500'}">{'Rounds History'}</a
        >
    </nav>
</div>
